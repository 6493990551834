<template>
    <div class="home background ">    
        <div class="panel blur">         
            <ul id="nav">
                    <li ><router-link to="/" >
                        <label class="web" style="text-decoration:none !important;">Inicio</label>
                        <font-awesome-icon class="phone"  icon="home" /></router-link>
                    </li>
                    <li class="twitter" v-on:click="setClass()"><label>Charges</label>
                        <ul v-bind:class="getClass()">
                            <li v-on:click="setDraw(1)">
                                <label> Alienígenas do Passado</label>
                            </li>
                            <li v-on:click="setDraw(2)">
                                <label> Oak Island</label>
                            </li>
                            <li v-on:click="setDraw(3)">
                                <label> Histórias da fazenda</label>
                            </li>
                            <li v-on:click="setDraw(4)">
                                <label> Histórias do trabalho</label>
                            </li>
                            <li v-on:click="setDraw(5)">
                                <label> Diversos</label>
                            </li>
                            <li v-on:click="setDraw(6)">
                                <label> Celebridades</label>
                            </li>
                            <li v-on:click="setDraw(7)">
                                <label> Pets</label>
                            </li>
                        </ul>
                    </li>
                    <li  ><router-link to="/about" style="text-decoration:none !important;" > 
                        <label class="web" style="text-decoration:none !important;">Como nasceu Merece1Desenho</label>
                        <font-awesome-icon class="phone" icon="head-side-virus" />
                    </router-link></li>
                    <li  class=""><router-link to="/contact" style="text-decoration:none !important;"> 
                        <label class="web" style="text-decoration:none !important;">Fale conosco</label>
                        <font-awesome-icon class="phone" icon="phone" /></router-link>
                    </li>
            </ul>
            <div class='panel-draw'>                
                <div v-bind:class="showimgHold()">
                    <carousel :wrap-around="false" v-model="currentSlide" :pauseAutoplayOnHover="true" id="car">
                        <slide  v-for="(src, index) in items"  :key="index" @click="() => showImg(index)">
                            <div>
                                <h2 class="titulo-a"> {{ src.title }}</h2>
                                <div calss="row">

                                    <img class="img" v-bind:class="showimgHold()"
                                        :alt="src['title']"
                                        :src="src['src']" >
                                </div>
                            </div>
                        </slide>
                        <template #addons>
                        <navigation />
                        </template>
                    </carousel>
                </div>
                
                <!-- <div class="row">
                    <div  v-bind:class="show(index)"
                        v-for="(src, index) in items"
                        :key="index"
                        class="pic col"
                        @click="() => showImg(index)"
                    >
                        <div >
                            <h2 class="titulo-a"> {{ src.title }}</h2>
                            <img v-bind:class="showimgHold()" class="img" :src="src['src']" />
                        </div>
                    </div>
                </div> -->

                <vue-easy-lightbox
                    :visible="visible"
                    :imgs="items"
                    :index="index"
                    @on-next="handleNext"
                    @on-prev="handleNext"
                    @hide="handleHide"
                ></vue-easy-lightbox>

            </div>
        </div>
    </div>
  </template>
  
  <script>
  import moment from 'moment'

    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide , Navigation} from 'vue3-carousel'
    import { defineComponent } from 'vue'

    import {useRoute} from "vue-router";

  
    export default defineComponent ({
      data() {
        return {
            divDisable:true,
            idTag: 1,
            active: false,
            imgActive:true,
            currentSlide: 0,
            idDisplay: 0,
            items:[],
            thumb:[],
            visible: false,
            index: 0, // default: 0
            imgs: [
                'https://via.placeholder.com/450.png/',
                'https://via.placeholder.com/300.png/',
                'https://via.placeholder.com/150.png/',
                { src: 'https://via.placeholder.com/450.png/', title: 'this is title' }
            ]
        };
      },
      async mounted(){
        this.callApi()        
        this.interval = setInterval(() => {
            if(document.querySelector('.vel-img') != undefined)  document.querySelector('.vel-img').style.backgroundColor = "transparent";
        }, 1000)
      },
      name: 'Home-',
      components: {
        Carousel,
        Slide,
        Navigation,
      },
    methods:{
        handleNext(anterior,novo){
            this.currentSlide = novo
            
            console.log(anterior)
            console.log(novo)
        },
        setClass(){
            this.active = !this.active
            console.log(this.active)
        },
        setDraw(id){
            this.index = 0
            this.currentSlide = 0
            this.idTag = id
            this.items = []
            this.thumb = []
            this.callApi()
        },
        getClass(){
            if(!this.active){
                return {
                    'displayN': 'none'
                }
            }else{
                return {
                    '': ''
                }
            }
        },
        show(i){
            this.divDisable = false
            if(i != this.currentSlide)
            return 'none';
        },
        showimgHold(){
            
            if(!this.imgActive)
            return 'none';
        },
        showImg(index) {
            this.divDisable = false
            this.imgActive=false
            this.index = index
            this.visible = true 
            this.interval = setInterval(() => {
                if(document.querySelector('.vel-img-title') != undefined){
                    document.querySelector('.vel-img-title').style.fontSize = '20px'
                    document.querySelector('.vel-img-title').style.top = '40px'
                }
            }, 1000)

        },
        handleHide() {
            // setTimeout(function() {
            // }, 2000);
            this.showimgHold()
            this.visible = false
            this.imgActive = !this.imgActive
        },
            format_date(value){
                if (value) {
                return moment(String(value)).format('DD/MM/YYYY')
                }
            },
            callApi(skip, limit ){
                if(skip == null){
                    skip =0;
                }
                if(limit == null){
                    limit =0;
                }
                const route = useRoute();
                var id = this.idTag
                if(route != null && route != undefined && route.params.id > 1){
                    id = route.params.id
                }
                console.log(this.index)
                // this.axios.post('http://localhost:8000/api/getDraw',
                this.axios.post('https://merece1desenho.com.br/Merece1DesenhoAPI/public/api/getDraw',
                {id_tag: id },
                {
                headers: {
                    'Content-Type': 'application/json',
                    //  'Cross-Origin-Read-Blocking': '*'
                },            
            }).then((response) => {
                if(response.status == 200 && response.data.length > 0){
                    response.data.forEach(element => {
                        var data = {}
                        data.date = this.format_date(element.created_at)
                        data.description = element.description
                        data.title = element.title
                        data.src = element.file.replace('home/u197702825/domains/merece1desenho.com.br/public_html/', '');

                        // http://localhost:8080/Merece1DesenhoAPI/public/img/Linda_Moulton_Howe_Ancient_Aliens.png
                        if(window.location.origin == 'http://localhost:8080'){
                            data.src = "https://merece1desenho.com.br"+ data.src;
                        }
                        console.log(data.seq)
                        // data.src = "https://merece1desenho.com.br/Merece1DesenhoAPI/public/img/Regras_do_General_Histórias_da_fazenda.png"
                        // 
                        // https://merece1desenho.com.br/Merece1DesenhoAPI/public/img/Regras_do_General_Histórias_da_fazenda.png
                        // https://merece1desenho.com.br/home/u197702825/domains/merece1desenho.com.br/public_html/Merece1DesenhoAPI/public/img/Regras_do_General_Histórias_da_fazenda.png
                        data.id = element.id
                        this.idDisplay  = element.id
                        this.items.push(data)
                        this.thumb.push(data)
                    });
                        var data = {}
                        data.file = 'element.file'
                        this.thumb.push(data)
                }
            },)
            },  
      }
    })
  </script>
  <style>
@media screen and (max-width: 600px) {
  .web{
    display: none !important;
  }
  #nav{
    align-items: baseline;
  }
#nav ul li {
  
  background: #333;
  /* width: 50px; */
  justify-content: space-around;
  padding-top: 10%;
  height: 50px;
}

}
@media screen and (min-width: 601px) {
  .phone{
    margin: auto;
    display: none !important;
  }
}
    .none{
        display:none;

    }
    .bakcground-img{    
        filter: blur(5px);
    }
    .home{
      width: 100%;
    }
    .panel{
        height: 80vh;
        width: 100vw;
    }
    .hidden{
        display: none;
    }
    .titulo-a{
        /* font-size: 7vh; */
        color: black;
        font-weight: 700;
    }
    .descricao-a{
        font-size: 4vh;
        color: black;
        font-weight: 500;
    }
    .panel-draw{
        width: 95vw;
        position: absolute;
        top:0;
        bottom: 0;
        left: 0;
        right: 0;    
        margin: auto;
    }
    .img{
        max-height: 80vh;
        max-width: 90vw;
    }
    .blur {
        background: rgba(255, 255, 255, 0.2); 
        backdrop-filter: blur(8px); 
        height: 100vh;
        width: 100%;
    }
    .volt{
        padding: 10px;
        width: 10%;
    }
    .volt-tit{
        font-size: 2vh;
        text-decoration: none;
        text-align: start;
        color: #000;
        font-weight: 500;
    }
    /* menu  */
    

    #nav {
        position: sticky;
        z-index: 1;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-around;
        height: 50px;
        background: #333;
        /* min-width: 500px; */
        margin-left: 0px;
        padding-left: 0px;
    }

    #nav li {
    text-decoration: none;
      list-style: none;
      display: block;
      float: left;
      height: 40px;
    }

    #nav li label {
    text-decoration: none;
      padding: 0px 10px 0px 30px;
      margin: 0px 0;
      line-height: 40px;
      text-decoration: none;
      height: 50px;
      color: #FFF;
    }

    #nav ul {
      background: #f2f5f6;
      padding: 0px;
    }

    #nav .site-name,
    #nav .site-name:hover {
      padding-left: 10px;
      padding-right: 10px;
      color: #FFF;
      width: 160px;
    }
    #nav ul li {
      background: #333;
      width: 300px;
      text-align: left;

      height: 50px;
    }

    #nav ul li:hover {
      background: #343a40;
    }
    #a{
        text-decoration:none !important;
    }
    .displayN{
        display: none;
    }
    .twitter{
        width: 200px;
    }
  </style>
  